import React from 'react';
import logo from './logo.svg';
import './App.scss';

function App() {
  return (
    <div>
      <div className="hero">
        <div className="shape1"/>
        <div className="shape2"/>
        <h2 className="montserrat">cherrypick</h2>
        <h1>
          내 취향 예쁜 옷<br />
          추천받으세요.
        </h1>
        <div className="app">
          <img src="/images/app-preview.png" alt="앱 예시 화면"/>
        </div>
      </div>
      <section className="introduce">
        <div className="group">
          <div className="title">
            좋아요, 별로에요<br/>
            취향 그대로 분석
          </div>
          <div className="desc">
            축적된 취향 데이터 기반으로<br/>
            내 취향에 맞게 추천받기
          </div>
          <img src="/images/swipe.png" alt="스와이프 설명" className="swipe" />
        </div>
        <div className="group">
          <div className="title">
            여러 쇼핑몰의 옷을<br/>
            한번에 모아서 -
          </div>
          <div className="desc">
            쇼핑몰로 왔다, 갔다 하지 않아도<br/>
            체리픽에서 즉시 구경할 수 있어요.
          </div>
          <img src="/images/shop-list.png" alt="제휴 쇼핑몰 리스트"/>
        </div>
      </section>
      <section className="i-wanna-buy">
        <div className="title">
          뭘 살지 모르겠지만<br/>
          일단 옷 사고 싶어.
        </div>
        <div className="desc">
          고민할 필요 없이<br/>
          내 취향에 맞는 옷을 구경하세요.
        </div>
        <div className="desc">
          체리픽을 만나보세요!<br />
          다운받기 ⬇
        </div>
        <div>
          <a href="https://play.google.com/store/apps/details?id=com.cherrypick.android" target="_blank">
            <img src="/images/available-on-google-play.png" alt="구글 플레이스토어에서 다운받기" />
          </a>
        </div>
      </section>
      <div className="footer">
        <div className="copyright">
          © 2020. cherrypick co., Ltd., All Rights Reserved.
          <ul>
            <li>주식회사 체리픽</li>
            <li>대표 이승환</li>
            <li>사업자등록번호 320-86-01907</li>
            <li>주소 서울특별시 강남구 논현로 509, 910호</li>
          </ul>
          <ul>
            <li>
              고객센터
              <br />
              {"카카오톡 "}
              <a href="http://pf.kakao.com/_pmmqK" target="_blank">
                @체리픽
              </a>
            </li>
          </ul>
          <ul>
            <li>제휴문의<br />이메일 leesh@cherries.kr</li>
          </ul>
          <ul>
            <li>체리픽은 통신판매중개자이며 통신판매의 당사자가 아닙니다. 따라서 상품·거래정보 및 거래에 대해 책임 지지않습니다.</li>
          </ul>
          <ul>
            <li className="montserrat">cherrypick</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default App;
